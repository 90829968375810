// Vue
import Vue from "vue";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faSignOut,
  faTrash,
  faEdit,
  faPlusSquare,
  faInfoCircle,
  faCircle,
  faChartLine,
  faTruck,
  faCircleNotch,
  faSortDown,
  faSortUp,
} from "@fortawesome/pro-solid-svg-icons";

import { faFileImport, faFileExport } from "@fortawesome/pro-duotone-svg-icons";

library.add(
  faSignOut,
  faTrash,
  faEdit,
  faPlusSquare,
  faInfoCircle,
  faCircle,
  faChartLine,
  faTruck,
  faFileImport,
  faFileExport,
  faCircleNotch,
  faSortDown,
  faSortUp
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
