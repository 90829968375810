<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <strong>Geburtstage</strong>
            <a class="plus" href="#" data-toggle="modal" data-target="#add" v-b-tooltip.hover.left
              title="Geburtstag erstellen">
              <font-awesome-icon :icon="['fas', 'plus-square']" @click="
                formModal = !formModal;
              clear();
              " />
            </a>
          </div>
          <div class="card-body p-2">
            <div v-if="postsData.length > 0">
              <b-table-simple striped class="m-0">
                <b-tr v-for="(element, index) in postsData" :key="`${index}`">
                  <b-td style="min-width: 300px;">{{ element.docData.name }}</b-td>
                  <b-td>{{ showDate(element.docData.birthday) }}</b-td>
                  <b-td style="width: 50px; ">
                    <div class="d-flex justify-content-between">
                      <div>
                        <a class="edit ml-4" href="#" data-toggle="modal" data-target="#edit" v-b-tooltip.hover.left
                          title="Bearbeiten">
                          <font-awesome-icon :icon="['fas', 'edit']" @click="edit(element)" />
                        </a>
                      </div>
                      <div>
                        <a class="delete ml-4" href="#" data-toggle="modal" data-target="#delete" v-b-tooltip.hover.left
                          title="Löschen">
                          <font-awesome-icon :icon="['fas', 'trash']" @click="deletePost(element.docId)" />
                        </a>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
              </b-table-simple>
            </div>
            <div class="p-2" v-else>
              <i v-if="!isLoading">Es gibt noch nichts zu sehen. Erstelle den ersten Geburtstag.</i>
              <div class="d-flex justify-content-center" v-else>
                <div class="spinner-border text-secondary"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal id="bv-modal-example" hide-footer v-model="formModal">
      <template #modal-title>
        <div v-if="name === ''">
          Geburtstag erstellen
        </div>
        <div v-else>
          Geburtstag bearbeiten
        </div>
      </template>
      <div class="d-block">
        <b-alert :show="alert.show" variant="danger">{{ alert.content }}</b-alert>
        <label class="font-weight-bold">Name</label>
        <b-form-input v-model.trim="name" placeholder="Vorname Nachname" text class="mb-3"></b-form-input>
        <label class="font-weight-bold">Geburtstag</label>
        <b-input-group class="mb-3">
          <b-form-input id="example-input" v-model="birthday" type="text" placeholder="JJJJ-MM-TT" autocomplete="off">
          </b-form-input>
          <b-input-group-append>
            <b-form-datepicker v-model="birthday" button-only right locale="de" aria-controls="example-input">
            </b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </div>
      <b-button class="mt-3" block @click="save()" variant="primary">Speichern</b-button>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash"

export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      isLoading: true,
      formId: null,
      formModal: false,
      postsData: [],
      name: "",
      birthday: "",
      alert: {
        show: false,
        content: "",
      },
    };
  },
  methods: {
    loadPostsData() {
      this.isLoading = true;
      this.$firestore.collection("birthdays")
        .onSnapshot((snapshot) => {
          this.postsData = [];
          const posts = [];
          snapshot.forEach((doc) => {
            posts.push({
              docId: doc.id,
              docData: doc.data(),
            });
          });
          this.postsData = _.orderBy(posts, (post) => {
            return post.docData.name.toLowerCase();
          }, "asc");
          this.isLoading = false;
        });
    },
    save() {
      if (this.name !== "" && this.birthday !== "") {
        if (this.formId === null) {
          const data = {
            name: this.name,
            birthday: this.birthday,
          };

          this.$firestore.collection("birthdays").add(data)
            .then(() => {
              this.formModal = !this.formModal;
              this.clear();
            })
            .catch((error) => console.error(error));
        } else {
          const data = {
            name: this.name,
            birthday: this.birthday,
          };

          this.$firestore.collection("birthdays").doc(this.formId)
            .update(data)
            .then(() => {
              this.formModal = !this.formModal;
              this.clear();
            })
            .catch((error) => console.error(error));
        }
      } else {
        this.showError(true);
      }
    },
    clear() {
      this.showError(false);
      this.formId = null;
      this.name = "";
      this.birthday = "";
    },
    edit(element) {
      this.clear();
      this.formId = element.docId;
      this.name = element.docData.name
      this.birthday = element.docData.birthday
      this.formModal = true;
    },
    deletePost(docId) {
      this.$firestore.collection("birthdays").doc(docId).delete()
        .catch((error) => console.error(error));
    },
    showDate(date) {
      return moment(date).locale("de").format("DD. MMMM YYYY");
    },
    showError(state) {
      if (state) {
        this.alert.content = "Bitte fülle alle Felder aus.";
        this.alert.show = true;
      } else {
        this.alert.show = false;
      }
    },
  },
  beforeMount() {
    this.loadPostsData();
  },
};
</script>
<style scoped>
strong,
td {
  color: #5c5c5c;
}

a.plus {
  color: rgb(196, 196, 196);
  font-size: 1.1rem;
}

a.plus:hover {
  color: #28a745;
}

a.edit {
  color: rgb(196, 196, 196);
  font-size: 1.1rem;
}

a.edit:hover {
  color: #ffc107;
}

a.delete {
  color: rgb(196, 196, 196);
  font-size: 1.1rem;
}

a.delete:hover {
  color: #dc3545;
}

.topBorder {
  border-top: 1px solid rgb(196, 196, 196);
}

.table tr:first-of-type td {
  border-top: 0;
}
</style>
