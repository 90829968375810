<template>
  <div>
    <navbar></navbar>
    <main class="py-4">
      <router-view></router-view>
    </main>
  </div>
</template>
<script>
import navbar from "./components/Navbar";
export default {
  components: {
    navbar,
  },
  componentDidMount() {
    this.$auth.onAuthStateChanged(
      function(user) {
        if (!user) {
          this.$router.replace({
            name: "login",
          });
          return;
        } else {
          this.$router.replace({
            name: "posts",
          });
        }
      }.bind(this)
    );
  },
};
</script>

<style>
</style>
