<template>
  <div v-if="$store.getters.user !== null">
    <div
      v-if="
        $store.getters.user.data.displayName === '' ||
          $store.getters.user.data.displayName === null ||
          $store.getters.user.data.displayName === undefined
      "
    >
      <b-modal
        size="m"
        tabindex="-1"
        role="dialog"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        v-model="showModal"
        aria-hidden="true"
        hide-footer
      >
        <p><b>Willkommen</b></p>
        <p>Damit das Programm richtig funktioniert, wird dein Vor- und Nachname benötigt.</p>
        <p>Bitte trage deinen Vor- und Nachnamen in das Feld ein und Speichere die einstellung.</p>
        <form autocomplete="off">
          <input type="text" class="form-control" id="name" v-model="userNameInput" placeholder="Vorname Nachname" />
        </form>
        <b-button variant="outline-primary" @click="saveMyName(personId)" block class="m-0 mt-3"
          ><font-awesome-icon :icon="['fas', 'save']" /> Speichern</b-button
        >
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CheckUserName",
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      userName: undefined,
      showModal: true,
      userNameInput: null,
    };
  },
  methods: {
    saveMyName() {
      this.$store.state.user.data.displayName = this.userNameInput;
      this.$auth.currentUser
        .updateProfile({
          displayName: this.userNameInput,
        })
        .then(() => {
          this.showModal = false;
        });
    },
  },
};
</script>
<style></style>
