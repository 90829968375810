import Vue from "vue";
import Login from "../components/Login";
import Posts from "../components/Posts";
import Birthdays from "../components/Birthdays";
import NotAllowed from "../components/NotAllowed";

import firebase from "firebase/app";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect: "/login",
    },
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        requiresAuth: false,
      },
    },
    { 
      path: '/token/:token', 
      name: 'token',
      component: Login,
      meta: {
          requiresAuth: false
      }
    },
    {
      path: "/posts",
      name: "posts",
      component: Posts,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/notallowed",
      name: "NotAllowed",
      component: NotAllowed,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/birthdays",
      name: "brithdays",
      component: Birthdays,
      meta: {
        requiresAuth: true,
      },
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await firebase.auth().currentUser;
  const getIdTokenResult = currentUser ? await currentUser.getIdTokenResult(): false
  const isUser = getIdTokenResult ? getIdTokenResult?.claims.user : false
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);


  if ((to.name === 'login' || to.name === 'NotAllowed') && currentUser && isUser) next({ name: 'Home' })
  else if (to.name !== 'NotAllowed' && !isUser && currentUser && requiresAuth) next({ name: 'NotAllowed' })
  else if (requiresAuth && currentUser) next();
  else if (requiresAuth && !currentUser) next({ name: "login" });
  else next();
});

export default router;
