<template>
  <nav v-if="user.loggedIn" class="navbar navbar-expand-lg navbar-light bg-primary">
    <div class="container">
      <a class="navbar-brand  d-flex text-white" href="#">
        <Logo :fill="'#ffffff'" :height="'33px'" :width="'33px'" class="mb-0 mr-2 d-flex align-items-center"></Logo>
        Sozialraummonitor
      </a>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto ml-3">
          <template v-if="user.loggedIn">
            <li class="nav-item">
              <router-link to="/posts" class="navbar-link">Beiträge</router-link>
            </li>
            <li class="nav-item ml-3">
              <router-link to="/Birthdays" class="navbar-link">Geburtstage</router-link>
            </li>
            <li class="nav-item ml-3">
              <a class="navbar-link" target="_blank" href="https://clgmbh-sozialraum-app.web.app/" >Vorschau</a>
            </li>
          </template>
        </ul>
      </div>
      <div>
        <template v-if="user.loggedIn">
          <a class="nav-link" href="#" @click.prevent="signOut" v-b-tooltip.hover.left title="Abmelden"
            ><font-awesome-icon :icon="['fas', 'sign-out']"
          /></a>
        </template>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import Logo from "../assets/Logo";
export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    Logo,
  },
  methods: {
    signOut() {
      this.$auth.signOut().then(() => {
        this.$router.replace("/");
      });
    },
  },
};
</script>
<style>
.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
a.nav-link,
li a {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.788) !important;
}
a.nav-link:hover,
li a:hover {
  color: rgb(255, 255, 255) !important;
}
</style>
