import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./routes/index";

// Font Awesome
import "./fontawesome.js";

// Bootstrap
import BootstrapVue from "bootstrap-vue";
import "@comlinedev/bootstrap4/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyCRX9jK_kAkBKwoqMonSWbMhF2FZUdOgrw",
  authDomain: "clgmbh-sozialraummonitor.firebaseapp.com",
  databaseURL: "https://clgmbh-sozialraummonitor.firebaseio.com",
  projectId: "clgmbh-sozialraummonitor",
  storageBucket: "clgmbh-sozialraummonitor.appspot.com",
  messagingSenderId: "67165048020",
  appId: "1:67165048020:web:09a63046e644d7f85cbf41",
});

const auth = firebase.auth();
const storage = firebase.storage();
const firestore = firebase.firestore();

if (location.hostname === "localhost") {
  auth.useEmulator("http://localhost:9099/", { disableWarnings: true });
  storage.useEmulator("localhost", 9199);
  firestore.useEmulator("localhost", 8081);
}

Vue.use(BootstrapVue);
Vue.config.productionTip = true;

// App Init
auth.onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
  let app = "";
  if (!app) {
    app = new Vue({
      render: (h) => h(App),
      router,
      store,
      beforeMount() {
        Vue.prototype.$auth = auth;
        Vue.prototype.$storage = storage;
        Vue.prototype.$firestore = firestore;
      },
    }).$mount("#app");
  }
});
